import React from 'react';

import keyledsLogo from '../images/resume-keyleds.png';
import botcryptoLogo from '../images/resume-botcrypto.png';
import hvadLogo from '../images/resume-django.png';
import groundlinkLogo from '../images/resume-groundlink.png';
import wavestoneLogo from '../images/resume-wavestone.png';

import amfLogo from '../images/resume-amf.png';
import bctLogo from '../images/resume-bct.png';
import bnpLogo from '../images/resume-bnp.png';
import carrefourLogo from '../images/resume-carrefour.png';
import casaLogo from '../images/resume-casa.png';
import laposteLogo from '../images/resume-laposte.png';
import totalLogo from '../images/resume-total.png';


const formatList = (data, listClass, itemClass) => (
    <ul className={listClass}>{data.map(str => <li className={itemClass}>{str}</li>)}</ul>
);

export const bct = (Experience) => (
    <Experience id="bct"
                title="Technical leadership – senior software engineer"
                company={{ name: 'BCT Technology AG', logo: bctLogo,
                            link: 'https://bct-technology.com/'}}>
        <p className="statement">
            As the manufacturing industry embraces digital transformation, BCT, a software
            company with decades of well-established solutions for product
            lifecycle management (PLM), needed to modernize its development practices
            to align with its partner, Siemens, in delivering value faster.
        </p>
        <p><ul>
            <li className="action">I championed the concept of technical leadership, developing
                a <strong>strategic vision</strong> for technical evolution to support
                the <strong>long-term growth</strong> of our products.
                As part of this initiative, I <strong>guided</strong> the team through the
                fundamentals of DevOps, using Gitlab CI and Docker,
                and <strong>facilitated</strong> the collaborative redefinition of our working processes.
                I then <strong>coordinated</strong> the implementation phase.
            </li>
            <li className="result">Over 4 years, I led the team to update its practices in
                development, dependency managment, continuous integration and release management.
                These efforts resulted in a <strong>75% reduction</strong> in time overhead
                for releases, the <strong>elimination</strong> of outdated and insecure
                components in releases, and significantly improved business resilience.
            </li>
            <li className="action">To <strong>lead by example</strong>, I took
                responsibility for and <strong>reorganized</strong> a critical software project
                integrating key functionalities from our existing C++ codebases into
                our partner's Web platform, ActiveWorkspace. I notably addressed structural weaknesses
                in the design, <strong>cutting support cost</strong> on this project from
                15 to 3 man-weeks per year.
            </li>
            <li className="result">Within 2 years, I transformed our relationship with our partner from
                uneasy interactions and low expectations
                to <strong>positive collaboration</strong> based on trust,
                culminating in a <strong>succesful go-to-market</strong>.
            </li>
        </ul></p>
        <p>{formatList(['Qt', 'C++20', 'WebAssembly', 'GitLab CI', 'Docker', 'Siemens NX'], 'tools', 'tool')}</p>
    </Experience>
);

export const keyleds = (Experience) => (
    <Experience id="keyleds"
                title="Reverse-engineering – driver development"
                company={{ name: 'keyleds', logo: keyledsLogo,
                            link: 'https://github.com/keyleds/keyleds'}}>
        <p className="statement">
            Provide Linux support for Logitech RGB keyboards, including
            animations that respond to user keypresses and desktop actions.
        </p>
        <p><ul>
            <li className="action"><strong>Reverse-engineered</strong> the proprietary
                protocol using an isolated environment to capture USB frames.</li>
            <li className="action"><strong>Implemented</strong> hardware support
                library with <b>Python bindings</b>.</li>
            <li className="action"><strong>Designed</strong> a
                dynamic <b>plugin architecture</b> and
                embedded <b>LUA interpreter</b> to enable users to create their
                own visual effects.</li>
            <li className="action"><strong>Optimized</strong> critical code paths
                using <b>SIMD intrisics</b>,
                achieving 12× increase in performance.</li>
            <li className="action"><strong>Integrated</strong> with Linux desktop
                ecosystem to improve user experience (eg: detecting device insertion).</li>
        </ul></p>
        <p>{formatList(['WireShark', 'CMake', 'Cython', 'C++17', 'LuaJIT', 'HID protocol'], 'tools', 'tool')}</p>
    </Experience>
);

export const botcrypto = (Experience) => (
    <Experience id="botcrypto"
                title="Architecture refactoring"
                company={{ name: 'botcrypto.io', logo: botcryptoLogo,
                            link: 'https://botcrypto.io/'}}>
        <p className="statement">
            Botcrypto was about to release a SaaS trading automation
            solution for retail traders. But initial tests showed performance issues,
            severe enough to prevent launch.
        </p>
        <p><ul>
            <li className="action">Tracked bottlenecks and identified software
                architecture problems.</li>
            <li className="action"><strong>Redesigned</strong> the architecture as a
                collection of <b>microservices</b>.</li>
            <li className="action">Replaced house-built tools with industry standards
                where appropriate.</li>
            <li className="action">Followed development as an <b>architecture advisor</b>,
                participating in design decisions and reviews.</li>
            <li className="result"><strong>Achieved a 1000× increase in overall
                system throughput.</strong></li>
        </ul></p>
        <p>{formatList(['Python 3', 'asyncio', 'Docker', 'PostgreSQL', 'redis', 'websockets'], 'tools', 'tool')}</p>
    </Experience>
);

export const hvad = (Experience) => (
    <Experience id="hvad"
                title="Refactoring and maintenance"
                company={{ name: 'django-hvad', logo: hvadLogo,
                            link: 'https://github.com/KristianOellegaard/django-hvad'}}>
        <p className="statement">
            Third-party add-on for Django with large user base and stale development
            required cleanup and updates, revolving around two main issues:
        </p>
        <p><ul>
            <li className="issue">Rampant compatibility issues spread throughout the code base as
                new Django releases came out.
            <ul>
                <li className="action"><strong>Circumscribed</strong>{' dependent code, '}
                    <strong>abstracted out</strong> its functionality
                    and <strong>encapsulated</strong> it into generic objects.</li>
                <li className="result"><strong>Reduced average modules affected by subsequent
                    releases 3-fold.</strong></li>
            </ul></li>
            <li className="issue">Inconsistencies in API compounded with large user base making
                evolutions difficult.
            <ul>
                <li className="action"><strong>Designed</strong> a cleaner API while
                    retaining backwards compatibility.</li>
                <li className="action">Then <strong>phased out</strong> old API with
                    self-documenting warnings.</li>
                <li className="result">Shift to new API caused <strong>no measurable
                    increase in new tickets.</strong></li>
            </ul></li>
        </ul></p>
        <p>{formatList(['Python 3', 'Django', 'Django-REST-framework', 'Travis-CI'], 'tools', 'tool')}</p>
    </Experience>
);

export const etherinfra = (Experience) => (
    <Experience id="etherinfra"
                title="Infrastructure automation"
                company={{ name: 'etherdream' }}>
        <p className="statement">
            An independent worker using hosted services (3-tier apps, VPN, data storage) for his
            daily activities wanted to ensure the availability of his work tools and the safety of
            his data, within tight budget constraints.
        </p>
        <p><ul>
            <li className="action"><strong>Identified</strong>{' services, '}
                <strong>formalized</strong> architecture and configurations.</li>
            <li className="action">Leveraged <b>IT automation</b> to allow quick rebuild
                in case of failure, lifting the need for spares.</li>
            <li className="action"><strong>Classified</strong> data, set up encryption
                and backup policies and <strong>developed</strong> ad-hoc scripts.</li>
            <li className="result">When failure happened in 2016, all <strong>services
                were business-ready within 1 hour</strong> of triggering automated
                recovery and <strong>no data was lost</strong>.</li>
        </ul></p>
        <p>{formatList(['Python 2', 'Ansible', 'PostgreSQL'], 'tools', 'tool')}</p>
    </Experience>
);

export const groundlink = (Experience) => (
    <Experience id="groundlink"
                title="Design and implementation of Hyper-V infrastructure"
                company={{ name: 'GroundLink', logo: groundlinkLogo,
                            link: 'https://www.groundlink.com/'}}>
        <p className="statement">
            To <strong>rationalize</strong> the release cycle of its internal
            applications, GroundLink decided to introduce fully isolated development,
            staging and pre-production environments for its development teams in Serbia.
            To make this transition cost-effective, they chose to leverage
            virtualization and dynamic provisioning.
        </p>
        <p><ul>
            <li className="action"><strong>Profiled</strong> existing workload
                on physical infrastructure.</li>
            <li className="action"><strong>Designed</strong> and <strong>sized</strong> the target
                virtual infrastructures, providing <b>technology recommendations</b> based
                on <b>ROI evaluations</b>.</li>
            <li className="action"><strong>Coordinated</strong> deployment and migration
                with main office in New York.</li>
            <li className="action"><strong>Trained</strong> local administrators on
                the new environment.</li>
            <li className="result">Successfully migrated eligible projects to the new
                infrastructure, <strong>reducing hosting cost by 80%</strong>.</li>
        </ul></p>
        <p>{formatList(['Hyper-V', 'PowerShell', 'iSCSI'], 'tools', 'tool')}</p>
    </Experience>
);

export const solucom = (Experience) => {
    const renderClient = (name, logo, url) => (
        <li className="client">
            <a href={url} rel="external noopener noreferrer" target="_blank">
                <img className="client-logo" src={logo} alt={name} />
            </a>
        </li>
    );
    return (
    <Experience id="solucom"
                title="IT Architecture consulting"
                company={{ name: 'Wavestone', logo: wavestoneLogo,
                            link: 'https://www.wavestone.com/'}}>
        <p className="statement">
            <strong>Infrastructure expert</strong> focusing on <b>cross-cutting concerns</b>
            , <b>rationalization</b> and <b>virtualization</b>.
            Conducted a total of 19 missions between 2005 and 2011.
        </p>
        <p>Typical work revolved around transformations resulting from the introduction of
            x86 virtualization technologies in datacenters, including both technical
            and organizational implications.
        </p>
        <p>Specific content varied a lot and included:
        <ul className="missions"><li>
            Technology intelligence, opportunity analysis, ROI estimation, IS architecture
            design, migration planning, audits, problem solving,
            development of ad-hoc tools.
        </li></ul></p>
        <p><ul className="clients">
            {renderClient('Financial authorities', amfLogo, 'https://www.amf-france.org/')}
            {renderClient('BNP Paribas', bnpLogo, 'https://group.bnpparibas/')}
            {renderClient('Carrefour', carrefourLogo, 'http://www.carrefour.com/')}
            {renderClient('Crédit Agricole SA', casaLogo, 'https://www.credit-agricole.com/en/')}
            {renderClient('La Poste', laposteLogo, 'https://www.laposte.fr/')}
            {renderClient('Total Petrochemicals', totalLogo, 'https://www.total.com/')}
        </ul></p>
        <p>{formatList(['ITIL (Foundation-certified)', 'Service-Oriented Infrastructure'], 'tools', 'tool')}</p>
    </Experience>
    );
};

export const solucomalt = (Experience) => (
    <Experience id="solucom"
                title="IT Architecture consulting"
                company={{ name: 'Wavestone', logo: wavestoneLogo,
                           link: 'https://www.wavestone.com/'}}>
        <p className="statement">
            <strong>Infrastructure expert</strong> focusing on <b>cross-cutting concerns</b>
            , <b>rationalization</b> and <b>virtualization</b>.
            Conducted a total of 19 missions between 2005 and 2011.
        </p>
        <p>Typical work revolved around transformations resulting from the introduction of
            x86 virtualization technologies in datacenters, including both technical
            and organizational implications.
        </p>
        <p>Specific content varied a lot, and included:
        <ul className="missions"><li>
            Technology intelligence, opportunity analysis, ROI estimation, IS architecture
            design, migration planning, audits, problem solving,
            development of ad-hoc tools.
        </li></ul></p>
        <p><strong>➥ Next entries are selected missions.</strong></p>
    </Experience>
);

export const amf = (Experience) => (
    <Experience id="amf"
                title="Structuring of infrastructure operations"
                company={{ name: 'Financial authorities', logo: amfLogo,
                           link: 'https://www.amf-france.org/' }}>
        <p className="statement">
            After 2008 financial crisis, the French regulatory authority was assigned a major
            increase in scope and budget, leading to a ten-fold increase in IT operations
            department. Its organisation was deeply impacted, and in need of structuring.</p>
        <p><ul>
        <li className="action"><strong>Conducted</strong> interviews to assess current state
            of operation processes.</li>
        <li className="action">Introduced <b>ITIL</b>-based practices to structure operations
            following a <b>continuous improvement</b> paradigm. Most notably <b>change management</b>,
            <b>supervision</b> and <b>incident management</b>.</li>
        <li className="action"><strong>Provided individual support</strong> to IT management
            and handled communication towards teams.</li>
        <li className="result">{'Established weekly '}
            <a href="https://en.wikipedia.org/wiki/Change-advisory_board" rel="external noopener" target="_blank">CAB
            </a>, ensuring visibility of change decisions, with their risks
            and dependencies.</li>
        </ul></p>
        <p>{formatList(['ITIL', 'IWS Qualities', 'Nagios'], 'tools', 'tool')}</p>
    </Experience>
);

export const total = (Experience) => (
    <Experience id="total"
                title="Branch-office IT consolidation"
                company={{ name: 'Total', logo: totalLogo,
                           link: 'https://www.total.com/' }}>
        <p className="statement">
            To reduce IT cost of foreign branch offices, Total Petrochemicals had decided to
            consolidate and migrate local applications to the datacenters of the main office,
            creating a new virtualized infrastructure to host them.</p>
        <p><ul>
            <li className="action"><strong>Inventoried</strong> branch office applications,
                including <b>network flows</b> and <b>workload profiles</b>.</li>
            <li className="action"><strong>Designed</strong> the target architecture for
                both hosting platform and inter-office networking.</li>
            <li className="action"><strong>Communicated</strong> with branch office teams to
                create procedures and tools to streamline the migration.</li>
            <li className="action"><strong>Coordinated</strong> foreign branch offices and
                suppliers to minimize business impact of changes.</li>
            <li className="result">Completed deployment by the deadline
                , <strong>dividing physical hosting costs by 4.</strong></li>
        </ul></p>
        <p>{formatList(['BladeCenter', 'Riverbed', 'SAN', 'VMware'], 'tools', 'tool')}</p>
    </Experience>
);

export const casa = (Experience) => (
    <Experience id="casa"
                title="Virtualization technology intelligence"
                company={{ name: 'Crédit Agricole', logo: casaLogo,
                           link: 'https://www.credit-agricole.com/en/' }}>
        <p className="statement">
            For the launch of a consolidation plan of its 20 000-server multi-subsidiary
            information system, Crédit Agricole wanted to define corporate standards for
            virtualized hosting, based on state-of-the-art tools and practices as well as
            upcoming trends.</p>
        <p><ul>
            <li className="action"><strong>Assessed</strong> current practices among
                subsidiaries, looking for synergies and pain points.</li>
            <li className="action"><strong>Guided</strong> IT staff as they met major
                virtualisation vendors.</li>
            <li className="action"><strong>Conducted workshops</strong> using gathered data
                and field experience as input.</li>
            <li className="result"><strong>Synthesized</strong> decisions into a 2-year roadmap.</li>
        </ul></p>
    </Experience>
);

export const laposte = (Experience) => (
    <Experience id="laposte"
                title="Datacenter virtualization"
                company={{ name: 'La Poste', logo: laposteLogo,
                           link: 'https://www.laposte.fr/' }}>
        <p className="statement">As part of its cyclical technology upgrade, LaPoste decided
            to assess the viability of virtualizing its 250-server ecosystem.</p>
        <p><ul>
            <li className="action"><strong>Inventoried</strong> and <strong>profiled
                </strong> running applications.</li>
            <li className="action"><strong>Designed</strong> several target scenarios with <b>
                migration paths</b>, <b>growth plans</b> and <b>ROI evaluation</b>.</li>
            <li className="action"><strong>Synthesized</strong> findings and technical risks
                analysis in a <b>decision document</b>.</li>
            <li className="action"><strong>Designed</strong> the <b>detailed technical architecture
                </b> after management selected a scenario.</li>
            <li className="result">Transformation project achieved <strong>neutral ROI within
                2 years</strong>, while <strong>dividing TCO by 6</strong>.</li>
        </ul></p>
        <p>{formatList(['VMware'], 'tools', 'tool')}</p>
    </Experience>
);

export const c4b2b = (Experience) => (
    <Experience id="c4b2b"
                title="Network connectivity issue investigation"
                company={{ name: 'Carrefour', logo: carrefourLogo,
                           link: 'http://www.carrefour.com/' }}>
        <p className="statement">Intermittent connectivity issues with foreign B2B partners led
            to degrading performance and occasional loss of business data.</p>
        <p><ul>
            <li className="action"><strong>Set up</strong> frame capture at backbone level
                and designed heuristics to discriminate relevant data.</li>
            <li className="action"><strong>Tracked down</strong> issue to a combination of
                protocol violations at equipment level and external network conditions
                that would compound under pathological circumstances.</li>
            <li className="action"><strong>Recommended</strong> configuration changes to reduce
                impact of issue.</li>
            <li className="result"><strong>No business data loss was reported once changes
                were applied.</strong></li>
        </ul></p>
        <p>{formatList(['Cisco Catalyst', 'Python', 'WireShark'], 'tools', 'tool')}</p>
    </Experience>
);

export const c4bench = (Experience) => (
    <Experience id="c4bench"
                title="Performance investigation in virtual environment"
                company={{ name: 'Carrefour', logo: carrefourLogo,
                           link: 'http://www.carrefour.com/' }}>
        <p className="statement">First deployment of a virtualized datacenter in Europe.
            Release-blocking performance issue in hosted applications threatened to make
            management abort the project.
            Oracle instances seemed to perform exceptionally poorly under VMware.</p>
        <p><ul>
            <li className="issue">Oracle internals were not well documented and Oracle refused to
                provide assistance.
            <ul>
                <li className="action"><strong>Traced</strong> application behavior at system
                    level and <strong>identified</strong> problematic patterns.</li>
            </ul></li>
            <li className="issue">Pioneering virtual environments meant no usable model of the
                workload existed at the time.
            <ul>
                <li className="action"><strong>Isolated</strong> problematic behaviors,
                    then <strong>instrumented</strong> them by creating <b>test cases
                    </b> and <b>benchmarks</b>.</li>
            </ul></li>
            <li className="result"><strong>Proved</strong> that virtualization was not the
                cause. <strong>Provided guidance</strong> on
                solving underlying issue.</li>
            <li className="result"><strong>Prevented project from being aborted</strong>,
                as application went into production.</li>
        </ul></p>
        <p>{formatList(['C99', 'Oracle 9i', 'VMware ESX'], 'tools', 'tool')}</p>
    </Experience>
);

