import React from 'react';
import { Link } from 'gatsby';


export const developer = () => (<>
    <div className="side-by-side">
        <dl className="side">
            <dt>Core languages</dt>
            <dd>C++20, Python 3, JavaScript.</dd>
            <dt>Technology</dt>
            <dd>
                Extensive experience in:
                <ul><li>
                    Sys­tem pro­gram­ming, net­work­ing, web ap­pli­ca­tions, em­bed­ded soft­ware.
                </li>
                <li>DevOps, continuous integration (CI/CD), containerization (Docker).</li>
                <li>Infrastructure-as-Code (Ansible, Packer).</li>
                </ul>
            </dd>
            <dt>Architecture</dt>
            <dd>
                Proficient in:
                <ul>
                <li>Communicating complex concepts to business stakeholders.</li>
                <li>Designing maintainable software architectures.</li>
                <li>Implementing common architectural design patterns.</li>
            </ul></dd>
        </dl>
        <dl className="side">
            <dt>Technical leadership</dt>
            <dd>
                Proven skills in:
                <ul>
                <li>Strategic planning and excution.</li>
                <li>Mentoring and developing engineering talent.</li>
                <li>Driving innovation and continuous improvement.</li>
            </ul></dd>
            <dt>Craftsmanship</dt>
            <dd>
                Commitment to:
                <ul>
                <li>Rapid self-learning and adaptability.</li>
                <li>Systemic approach to problem solving.</li>
                <li>Delivering quality, well-polished solutions.</li>
            </ul></dd>
            <dt>Consulting toolbox</dt>
            <dd>
                Equipped with:
                <ul>
                <li>Effective coaching and teaching abilities.</li>
                <li>Strong presentation and communication skills.</li>
                <li>Outstanding writing skills.</li>
            </ul></dd>
        </dl>
    </div>
    <p className="remark">
        ➥ Deep knowledge of IS architecture and operations also provides
        an edge in software design. See <Link to="/resume/IT-architect">résumé</Link>.</p>
</>);

export const itexpert = () => (<>
    <div className="side-by-side">
        <dl className="side">
            <dt>IS transformation</dt>
            <dd><ul>
                <li>Opportunity analysis / ROI estimation.</li>
                <li>IS architecture design.</li>
                <li>Migration planning and coordination.</li>
                <li>Auditing / problem solving.</li>
            </ul></dd>
            <dt>IT operations</dt>
            <dd><ul>
                <li><strong>Processes:</strong> change management, capacity planning,
                    backup, monitoring, supervision, incident management.</li>
                <li>Extensive knowledge of day-to-day operating environments.</li>
                <li>ITIL Foundation certified.</li>
            </ul></dd>
        </dl>
        <dl className="side">
            <dt>IT infrastructure</dt>
            <dd><ul>
                <li><strong>Operating systems:</strong> GNU/Linux, Windows.</li>
                <li><strong>Networking:</strong> OSI model, firewalls, load balancers, WAN optimizers.</li>
                <li><strong>Storage:</strong> SAN, NAS, iSCSI.</li>
                <li><strong>Virtualization:</strong> VMware, XenServer, Hyper‑V.</li>
            </ul></dd>
            <dt>Consulting toolbox</dt>
            <dd><ul>
                <li>Listening and analytical thinking.</li>
                <li>Coaching and teaching.</li>
                <li>Excellent writing skills.</li>
            </ul></dd>
        </dl>
    </div>
    <p className="remark">
        ➥ Long experience of software development provides
        insights and tools for IS architecture. See <Link to="/resume">résumé</Link>.</p>
</>);
